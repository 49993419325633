import {CATEGORY_LIST_REQUEST, CATEGORY_LIST_SUCCESS, CATEGORY_LIST_FAIL, CATEGORY_CREATE_REQUEST, CATEGORY_CREATE_SUCCESS, CATEGORY_CREATE_FAIL, CATEGORY_CREATE_RESET, CATEGORY_LIST_DETAIL_REQUEST, CATEGORY_LIST_DETAIL_SUCCESS, CATEGORY_LIST_DETAIL_FAIL, CATEGORY_UPDATE_REQUEST, CATEGORY_UPDATE_SUCCESS, CATEGORY_UPDATE_FAIL, CATEGORY_UPDATE_RESET,
    PRODUCT_DETAILS_BY_CATEGORY_REQUEST, PRODUCT_DETAILS_BY_CATEGORY_SUCCESS, PRODUCT_DETAILS_BY_CATEGORY_FAIL, PRODUCT_DETAILS_BY_CATEGORY_RESET} from '../constants/categoryConstants'

export const categoryListReducer = (state = {categories: []}, action) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return {loading: true, categories: []}
        case CATEGORY_LIST_SUCCESS:
            return {loading: false, categories: action.payload}
        case CATEGORY_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const categoryDetailsReducer = (state = { categories : []}, action) => {
    switch (action.type) {
        case CATEGORY_LIST_DETAIL_REQUEST:
            return {loading: true, categories: []}
        case CATEGORY_LIST_DETAIL_SUCCESS:
            return {loading: false, categories: action.payload}
        case CATEGORY_LIST_DETAIL_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const categoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORY_CREATE_REQUEST:
            return {loading: true}
        case CATEGORY_CREATE_SUCCESS:
            return {loading: false, success: action.payload}
        case CATEGORY_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case CATEGORY_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const categoryUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORY_UPDATE_REQUEST:
            return {loading: true}
        case CATEGORY_UPDATE_SUCCESS:
            return {loading: false, success: action.payload}
        case CATEGORY_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case CATEGORY_UPDATE_RESET:
            return {}
        default:
            return state
    }
}

export const productDetailsByCategoryReducers = (state = { products: [] }, action) => {
    switch (action.type) {
      case PRODUCT_DETAILS_BY_CATEGORY_REQUEST:
        return {
          loading: true,
        }
      case PRODUCT_DETAILS_BY_CATEGORY_SUCCESS:
        return {
          loading: false,
          productDetails: action.payload,
        }
      case PRODUCT_DETAILS_BY_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case PRODUCT_DETAILS_BY_CATEGORY_RESET:
        return {
            products: [],
        }
      default:
        return state
    }
}