export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_SALE_LIST_REQUEST = 'PRODUCT_SALE_LIST_REQUEST'
export const PRODUCT_SALE_LIST_SUCCESS = 'PRODUCT_SALE_LIST_SUCCESS'
export const PRODUCT_SALE_LIST_FAIL = 'PRODUCT_SALE_LIST_FAIL'

export const PRODUCT_FUSION_LIST_REQUEST = 'PRODUCT_FUSION_LIST_REQUEST'
export const PRODUCT_FUSION_LIST_SUCCESS = 'PRODUCT_FUSION_LIST_SUCCESS'
export const PRODUCT_FUSION_LIST_FAIL = 'PRODUCT_FUSION_LIST_FAIL'

export const PRODUCT_LOAN_LIST_REQUEST = 'PRODUCT_LOAN_LIST_REQUEST'
export const PRODUCT_LOAN_LIST_SUCCESS = 'PRODUCT_LOAN_LIST_SUCCESS'
export const PRODUCT_LOAN_LIST_FAIL = 'PRODUCT_LOAN_LIST_FAIL'

export const PRODUCT_LIST_DETAIL_REQUEST = 'PRODUCT_LIST_DETAIL_REQUEST'
export const PRODUCT_LIST_DETAIL_SUCCESS = 'PRODUCT_LIST_DETAIL_SUCCESS'
export const PRODUCT_LIST_DETAIL_FAIL = 'PRODUCT_LIST_DETAIL_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'