import {
  REDEEMPOINT_CREATE_REQUEST,
  REDEEMPOINT_CREATE_SUCCESS,
  REDEEMPOINT_CREATE_FAIL,
  REDEEMPOINT_CREATE_RESET,
  REDEEMPOINT_LIST_REQUEST,
  REDEEMPOINT_LIST_SUCCESS,
  REDEEMPOINT_LIST_FAIL
} from '../constants/redeemPointConstants'

export const redeemPointCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case REDEEMPOINT_CREATE_REQUEST:
      return {loading: true}
    case REDEEMPOINT_CREATE_SUCCESS:
      return {loading: false, success: action.payload}
    case REDEEMPOINT_CREATE_FAIL:
      return {loading: false, error: action.payload}
    case REDEEMPOINT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const redeemPointListReducer = (state = {redeemPoints: []}, action) => {
  switch (action.type) {
    case REDEEMPOINT_LIST_REQUEST:
      return {loading: true, redeemPoints: []}
    case REDEEMPOINT_LIST_SUCCESS:
      return {loading: false, redeemPoints: action.payload}
    case REDEEMPOINT_LIST_FAIL:
      return {loading: false, error: action.payload}
    default:
      return state
  }
}