import {
    PRODUCT_LIST_REQUEST, 
    PRODUCT_LIST_SUCCESS, 
    PRODUCT_LIST_FAIL, 
    PRODUCT_CREATE_REQUEST, 
    PRODUCT_CREATE_SUCCESS, 
    PRODUCT_CREATE_FAIL, 
    PRODUCT_CREATE_RESET, 
    PRODUCT_LIST_DETAIL_REQUEST, 
    PRODUCT_LIST_DETAIL_SUCCESS, 
    PRODUCT_LIST_DETAIL_FAIL, 
    PRODUCT_UPDATE_REQUEST, 
    PRODUCT_UPDATE_SUCCESS, 
    PRODUCT_UPDATE_FAIL, 
    PRODUCT_UPDATE_RESET,
    PRODUCT_SALE_LIST_REQUEST,
    PRODUCT_SALE_LIST_SUCCESS,
    PRODUCT_SALE_LIST_FAIL,
    PRODUCT_FUSION_LIST_REQUEST,
    PRODUCT_FUSION_LIST_SUCCESS,
    PRODUCT_FUSION_LIST_FAIL,
    PRODUCT_LOAN_LIST_REQUEST,
    PRODUCT_LOAN_LIST_SUCCESS,
    PRODUCT_LOAN_LIST_FAIL
} from '../constants/productConstants'

export const productListReducer = (state = {products: []}, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
        return {loading: true, products: []}
    case PRODUCT_LIST_SUCCESS:
        return {loading: false, products: action.payload}
    case PRODUCT_LIST_FAIL:
        return {loading: false, error: action.payload}
    default:
        return state
  }
}

export const productSaleListReducer = (state = {products: []}, action) => {
  switch (action.type) {
    case PRODUCT_SALE_LIST_REQUEST:
      return {loading: true, products: []}
    case PRODUCT_SALE_LIST_SUCCESS:
      return {loading: false, products: action.payload}
    case PRODUCT_SALE_LIST_FAIL:
      return {loading: false, error: action.payload}
    default:
      return state
  }
}

export const productFusionListReducer = (state = {products: []}, action) => {
  switch (action.type) {
    case PRODUCT_FUSION_LIST_REQUEST:
      return {loading: true, products: []}
    case PRODUCT_FUSION_LIST_SUCCESS:
      return {loading: false, products: action.payload}
    case PRODUCT_FUSION_LIST_FAIL:
      return {loading: false, error: action.payload}
    default:
      return state
  }
}

export const productLoanListReducer = (state = {products: []}, action) => {
    switch (action.type) {
        case PRODUCT_LOAN_LIST_REQUEST:
            return {loading: true, products: []}
        case PRODUCT_LOAN_LIST_SUCCESS:
            return {loading: false, products: action.payload}
        case PRODUCT_LOAN_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const productDetailsReducer = (state = { product : []}, action) => {
    switch (action.type) {
        case PRODUCT_LIST_DETAIL_REQUEST:
            return {loading: true, product: []}
        case PRODUCT_LIST_DETAIL_SUCCESS:
            return {loading: false, product: action.payload}
        case PRODUCT_LIST_DETAIL_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const productCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_REQUEST:
            return {loading: true}
        case PRODUCT_CREATE_SUCCESS:
            return {loading: false, success: action.payload}
        case PRODUCT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case PRODUCT_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const productUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_UPDATE_REQUEST:
            return {loading: true}
        case PRODUCT_UPDATE_SUCCESS:
            return {loading: false, success: action.payload}
        case PRODUCT_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case PRODUCT_UPDATE_RESET:
            return {}
        default:
            return state
    }
}