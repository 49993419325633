import {
    INVOICE_LIST_DETAIL_REQUEST,
    INVOICE_LIST_DETAIL_SUCCESS,
    INVOICE_LIST_DETAIL_FAIL,
    PURCHASE_INVOICE_LIST_DETAIL_REQUEST,
    PURCHASE_INVOICE_LIST_DETAIL_SUCCESS,
    PURCHASE_INVOICE_LIST_DETAIL_FAIL,
}
from '../constants/invoiceConstants'

export const invoiceDetailsReducer = (state = { invoice : []}, action) => {
    switch (action.type) {
        case INVOICE_LIST_DETAIL_REQUEST:
            return {loading: true, invoice: []}
        case INVOICE_LIST_DETAIL_SUCCESS:
            return {loading: false, invoice: action.payload}
        case INVOICE_LIST_DETAIL_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const invoiceDetailsByPurchaseReducer = (state = { invoice : []}, action) => {
    switch (action.type) {
        case PURCHASE_INVOICE_LIST_DETAIL_REQUEST:
            return {loading: true, invoice: []}
        case PURCHASE_INVOICE_LIST_DETAIL_SUCCESS:
            return {loading: false, invoice: action.payload}
        case PURCHASE_INVOICE_LIST_DETAIL_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}