import {
    TRANSACTION_CREATE_REQUEST,
    TRANSACTION_CREATE_SUCCESS,
    TRANSACTION_CREATE_FAIL,
    TRANSACTION_CREATE_RESET,
    TRANSACTION_BY_MEMBER_REQUEST,
    TRANSACTION_BY_MEMBER_SUCCESS,
    TRANSACTION_BY_MEMBER_FAIL,
    TRANSACTION_BY_MEMBER_RESET
} from '../constants/transactionConstans'

export const transactionCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case TRANSACTION_CREATE_REQUEST:
          return {loading: true}
      case TRANSACTION_CREATE_SUCCESS:
          return {loading: false, success: action.payload}
      case TRANSACTION_CREATE_FAIL:
          return {loading: false, error: action.payload}
      case TRANSACTION_CREATE_RESET:
          return {}
      default:
          return state
  }
}

export const transactionByMemberReducers = (state = { transactions: [] }, action) => {
  switch (action.type) {
    case TRANSACTION_BY_MEMBER_REQUEST:
      return {
        loading: true,
      }
    case TRANSACTION_BY_MEMBER_SUCCESS:
      return {
        loading: false,
        transactions: action.payload,
      }
    case TRANSACTION_BY_MEMBER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case TRANSACTION_BY_MEMBER_RESET:
      return {
        transactions: [],
      }
    default:
      return state
  }
}