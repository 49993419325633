export const REPORT_STOCK_DETAIL_REQUEST = 'REPORT_STOCK_DETAIL_REQUEST'
export const REPORT_STOCK_DETAIL_SUCCESS = 'REPORT_STOCK_DETAIL_SUCCESS'
export const REPORT_STOCK_DETAIL_FAIL = 'REPORT_STOCK_DETAIL_FAIL'

export const REPORT_TRANSACTIONS_REQUEST = 'REPORT_TRANSACTIONS_REQUEST'
export const REPORT_TRANSACTIONS_SUCCESS = 'REPORT_TRANSACTIONS_SUCCESS'
export const REPORT_TRANSACTIONS_FAIL = 'REPORT_TRANSACTIONS_FAIL'

export const REPORT_PURCHASES_REQUEST = 'REPORT_PURCHASES_REQUEST'
export const REPORT_PURCHASES_SUCCESS = 'REPORT_PURCHASES_SUCCESS'
export const REPORT_PURCHASES_FAIL = 'REPORT_PURCHASES_FAIL'

export const REPORT_POINT_HISTORY_REQUEST = 'REPORT_POINT_HISTORY_REQUEST'
export const REPORT_POINT_HISTORY_SUCCESS = 'REPORT_POINT_HISTORY_SUCCESS'
export const REPORT_POINT_HISTORY_FAIL = 'REPORT_POINT_HISTORY_FAIL'

export const REPORT_FUSIONS_REQUEST = 'REPORT_FUSIONS_REQUEST'
export const REPORT_FUSIONS_SUCCESS = 'REPORT_FUSIONS_SUCCESS'
export const REPORT_FUSIONS_FAIL = 'REPORT_FUSIONS_FAIL'

export const REPORT_TRADE_IN_REQUEST = 'REPORT_TRADE_IN_REQUEST'
export const REPORT_TRADE_IN_SUCCESS = 'REPORT_TRADE_IN_SUCCESS'
export const REPORT_TRADE_IN_FAIL = 'REPORT_TRADE_IN_FAIL'