export const MEMBER_LIST_REQUEST = 'MEMBER_LIST_REQUEST'
export const MEMBER_LIST_SUCCESS = 'MEMBER_LIST_SUCCESS'
export const MEMBER_LIST_FAIL = 'MEMBER_LIST_FAIL'

export const MEMBER_LIST_DETAIL_REQUEST = 'MEMBER_LIST_DETAIL_REQUEST'
export const MEMBER_LIST_DETAIL_SUCCESS = 'MEMBER_LIST_DETAIL_SUCCESS'
export const MEMBER_LIST_DETAIL_FAIL = 'MEMBER_LIST_DETAIL_FAIL'

export const MEMBER_CREATE_REQUEST = 'MEMBER_CREATE_REQUEST'
export const MEMBER_CREATE_SUCCESS = 'MEMBER_CREATE_SUCCESS'
export const MEMBER_CREATE_FAIL = 'MEMBER_CREATE_FAIL'
export const MEMBER_CREATE_RESET = 'MEMBER_CREATE_RESET'

export const MEMBER_UPDATE_REQUEST = 'MEMBER_UPDATE_REQUEST'
export const MEMBER_UPDATE_SUCCESS = 'MEMBER_UPDATE_SUCCESS'
export const MEMBER_UPDATE_FAIL = 'MEMBER_UPDATE_FAIL'
export const MEMBER_UPDATE_RESET = 'MEMBER_UPDATE_RESET'