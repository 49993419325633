export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const CATEGORY_LIST_DETAIL_REQUEST = 'CATEGORY_LIST_DETAIL_REQUEST'
export const CATEGORY_LIST_DETAIL_SUCCESS = 'CATEGORY_LIST_DETAIL_SUCCESS'
export const CATEGORY_LIST_DETAIL_FAIL = 'CATEGORY_LIST_DETAIL_FAIL'

export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST'
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS'
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL'
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET'

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST'
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS'
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL'
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET'

export const PRODUCT_DETAILS_BY_CATEGORY_REQUEST = 'PRODUCT_DETAILS_BY_CATEGORY_REQUEST'
export const PRODUCT_DETAILS_BY_CATEGORY_SUCCESS = 'PRODUCT_DETAILS_BY_CATEGORY_SUCCESS'
export const PRODUCT_DETAILS_BY_CATEGORY_FAIL = 'PRODUCT_DETAILS_BY_CATEGORY_FAIL'
export const PRODUCT_DETAILS_BY_CATEGORY_RESET = 'PRODUCT_DETAILS_BY_CATEGORY_RESET'