import React from 'react'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton
} from '@coreui/react'
import { useGlobalModalContext } from '../../GlobalModal'

const InfoModal = () => {
  const { hideModal, store } = useGlobalModalContext()
  const { modalProps } = store ?? {}
  const { message } = modalProps ?? {}

  const onClose = () => {
    hideModal()
  }

  return (
    <CModal
      show={true}
      onClose={onClose}
    >
      <CModalHeader>
        <CModalTitle>Info</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {message}
      </CModalBody>
      <CModalFooter>
        <CButton color='secondary' onClick={onClose}>
          Tutup
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default InfoModal
