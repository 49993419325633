import {
    MEMBER_POINT_HISTORY_BY_MEMBER_REQUEST,
    MEMBER_POINT_HISTORY_BY_MEMBER_SUCCESS,
    MEMBER_POINT_HISTORY_BY_MEMBER_FAIL,
    MEMBER_POINT_HISTORY_BY_MEMBER_RESET
} from '../constants/memberPointHistory'

export const memberPointHistoryByMemberReducers = (state = { memberPointHistory: [] }, action) => {
    switch (action.type) {
      case MEMBER_POINT_HISTORY_BY_MEMBER_REQUEST:
        return {
          loading: true,
        }
      case MEMBER_POINT_HISTORY_BY_MEMBER_SUCCESS:
        return {
          loading: false,
          memberPointHistory: action.payload,
        }
      case MEMBER_POINT_HISTORY_BY_MEMBER_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case MEMBER_POINT_HISTORY_BY_MEMBER_RESET:
        return {
            memberPointHistory: [],
        }
      default:
        return state
    }
  }