import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { 
  categoryListReducer, 
  categoryCreateReducer, 
  categoryDetailsReducer, 
  categoryUpdateReducer, 
  productDetailsByCategoryReducers
} from './redux/reducers/categoryReducer'
import { 
  giftListReducer, 
  giftCreateReducer, 
  giftDetailsReducer, 
  giftUpdateReducer, 
} from './redux/reducers/giftReducer'
import { invoiceDetailsByPurchaseReducer, invoiceDetailsReducer } from './redux/reducers/invoiceReducer'
import { memberListReducer, memberDetailsReducer, memberCreateReducer, memberUpdateReducer } from './redux/reducers/memberReducers'
import { memberPointHistoryByMemberReducers } from './redux/reducers/memberPointHistoryReducer'
import { 
  productListReducer,
  productSaleListReducer,
  productDetailsReducer, 
  productCreateReducer,
  productUpdateReducer, 
  productFusionListReducer,
  productLoanListReducer
} from './redux/reducers/productReducers'
import { purchaseCreateReducer } from './redux/reducers/purchaseReducer'
import { reportStockDetailReducer, reportTransactionsReducer, reportPurchasesReducer, reportPointHistoryReducer, reportFusionsReducer, reportTradeInReducer } from './redux/reducers/reportReducers'
import { supplierCreateReducer, supplierUpdateReducer, supplierDetailsReducer , supplierListReducer } from './redux/reducers/supplierReducers'
import { transactionCreateReducer, transactionByMemberReducers } from './redux/reducers/transactionReducers'
import { transactionDetailsByTransactionReducers } from './redux/reducers/transactionDetailsReducers'
import { redeemPointCreateReducer, redeemPointListReducer } from './redux/reducers/redeemPointReducer'
import { fusionCreateReducer } from './redux/reducers/fusionReducer'
import { 
  loanListReducer, 
  loanDetailsReducer,
  loanCreateReducer,
  loanPayReducer,
  loanSettleReducer,
  loanAddReducer,
  loanEditInterestReducer
} from './redux/reducers/loanReducer'

const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const giftReducers = combineReducers({
  giftList : giftListReducer,
  giftDetail : giftDetailsReducer,
  giftCreate : giftCreateReducer,
  giftUpdate : giftUpdateReducer
})

const loanReducers = combineReducers({
  loanCreate: loanCreateReducer,
  loanList : loanListReducer,
  loanDetail : loanDetailsReducer,
  loanPay: loanPayReducer,
  loanSettle: loanSettleReducer,
  loanEditInterest: loanEditInterestReducer,
  loanAdd: loanAddReducer,
})

const redeemPointReducer = combineReducers({
  create : redeemPointCreateReducer ,
  list : redeemPointListReducer
})

const fusionReducer = combineReducers({
  create: fusionCreateReducer
})

const reportReducer = combineReducers({
  stockDetail : reportStockDetailReducer,
  transactions: reportTransactionsReducer,
  purchases: reportPurchasesReducer,
  pointHistory: reportPointHistoryReducer,
  fusions: reportFusionsReducer,
  tradeIn: reportTradeInReducer
})

const reducer = combineReducers({
  categoryList: categoryListReducer,
  categoryCreate: categoryCreateReducer,
  categoryDetails: categoryDetailsReducer,
  categoryUpdate: categoryUpdateReducer,
  gifts: giftReducers,
  fusions: fusionReducer,
  loans: loanReducers,
  redeemPoint: redeemPointReducer,
  purchaseCreate: purchaseCreateReducer,
  invoiceDetails: invoiceDetailsReducer,
  invoicePurchase: invoiceDetailsByPurchaseReducer,
  memberList: memberListReducer,
  memberDetails: memberDetailsReducer,
  memberCreate: memberCreateReducer,
  memberUpdate: memberUpdateReducer,
  memberPointHistoryByMember : memberPointHistoryByMemberReducers,
  productList: productListReducer,
  productSaleList: productSaleListReducer,
  productFusionList: productFusionListReducer,
  productLoanList: productLoanListReducer,
  productDetails: productDetailsReducer,
  productDetailsByCategory: productDetailsByCategoryReducers,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  reports: reportReducer,
  supplierList: supplierListReducer,
  supplierDetails: supplierDetailsReducer,
  supplierCreate: supplierCreateReducer,
  supplierUpdate: supplierUpdateReducer,
  transactionCreate: transactionCreateReducer,
  transactionsByMember: transactionByMemberReducers,
  transactionDetailsByTransaction : transactionDetailsByTransactionReducers,
  coreui: changeState
})

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk)))
export default store