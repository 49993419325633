import { 
    REPORT_STOCK_DETAIL_REQUEST, 
    REPORT_STOCK_DETAIL_SUCCESS, 
    REPORT_STOCK_DETAIL_FAIL,
    REPORT_TRANSACTIONS_REQUEST,
    REPORT_TRANSACTIONS_SUCCESS,
    REPORT_TRANSACTIONS_FAIL,
    REPORT_POINT_HISTORY_REQUEST,
    REPORT_POINT_HISTORY_SUCCESS,
    REPORT_POINT_HISTORY_FAIL,
    REPORT_PURCHASES_REQUEST,
    REPORT_PURCHASES_SUCCESS,
    REPORT_PURCHASES_FAIL,
    REPORT_FUSIONS_REQUEST,
    REPORT_FUSIONS_SUCCESS,
    REPORT_FUSIONS_FAIL,
    REPORT_TRADE_IN_REQUEST,
    REPORT_TRADE_IN_SUCCESS,
    REPORT_TRADE_IN_FAIL
} from '../constants/reportConstants'

export const reportStockDetailReducer = (state = {reports: []}, action) => {
    switch (action.type) {
        case REPORT_STOCK_DETAIL_REQUEST:
            return {loading: true, reports: []}
        case REPORT_STOCK_DETAIL_SUCCESS:
            return {loading: false, reports: action.payload}
        case REPORT_STOCK_DETAIL_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const reportTransactionsReducer = (state = {reports: []}, action) => {
    switch (action.type) {
        case REPORT_TRANSACTIONS_REQUEST:
            return {loading: true, reports: []}
        case REPORT_TRANSACTIONS_SUCCESS:
            return {loading: false, reports: action.payload}
        case REPORT_TRANSACTIONS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const reportPurchasesReducer = (state = {reports: []}, action) => {
    switch (action.type) {
        case REPORT_PURCHASES_REQUEST:
            return {loading: true, reports: []}
        case REPORT_PURCHASES_SUCCESS:
            return {loading: false, reports: action.payload}
        case REPORT_PURCHASES_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const reportPointHistoryReducer = (state = {reports: []}, action) => {
    switch (action.type) {
        case REPORT_POINT_HISTORY_REQUEST:
            return {loading: true, reports: []}
        case REPORT_POINT_HISTORY_SUCCESS:
            return {loading: false, reports: action.payload}
        case REPORT_POINT_HISTORY_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    } 
}

export const reportFusionsReducer = (state = {reports: []}, action) => {
    switch (action.type) {
        case REPORT_FUSIONS_REQUEST:
            return {loading: true, reports: []}
        case REPORT_FUSIONS_SUCCESS:
            return {loading: false, reports: action.payload}
        case REPORT_FUSIONS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    } 
}

export const reportTradeInReducer = (state = {reports: []}, action) => {
    switch (action.type) {
        case REPORT_TRADE_IN_REQUEST:
            return {loading: true, reports: []}
        case REPORT_TRADE_IN_SUCCESS:
            return {loading: false, reports: action.payload}
        case REPORT_TRADE_IN_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    } 
}