export const SUPPLIER_LIST_REQUEST = 'SUPPLIER_LIST_REQUEST'
export const SUPPLIER_LIST_SUCCESS = 'SUPPLIER_LIST_SUCCESS'
export const SUPPLIER_LIST_FAIL = 'SUPPLIER_LIST_FAIL'

export const SUPPLIER_LIST_DETAIL_REQUEST = 'SUPPLIER_LIST_DETAIL_REQUEST'
export const SUPPLIER_LIST_DETAIL_SUCCESS = 'SUPPLIER_LIST_DETAIL_SUCCESS'
export const SUPPLIER_LIST_DETAIL_FAIL = 'SUPPLIER_LIST_DETAIL_FAIL'

export const SUPPLIER_CREATE_REQUEST = 'SUPPLIER_CREATE_REQUEST'
export const SUPPLIER_CREATE_SUCCESS = 'SUPPLIER_CREATE_SUCCESS'
export const SUPPLIER_CREATE_FAIL = 'SUPPLIER_CREATE_FAIL'
export const SUPPLIER_CREATE_RESET = 'SUPPLIER_CREATE_RESET'

export const SUPPLIER_UPDATE_REQUEST = 'SUPPLIER_UPDATE_REQUEST'
export const SUPPLIER_UPDATE_SUCCESS = 'SUPPLIER_UPDATE_SUCCESS'
export const SUPPLIER_UPDATE_FAIL = 'SUPPLIER_UPDATE_FAIL'
export const SUPPLIER_UPDATE_RESET = 'SUPPLIER_UPDATE_RESET'