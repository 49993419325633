export const GIFT_LIST_REQUEST = 'GIFT_LIST_REQUEST'
export const GIFT_LIST_SUCCESS = 'GIFT_LIST_SUCCESS'
export const GIFT_LIST_FAIL = 'GIFT_LIST_FAIL'

export const GIFT_LIST_DETAIL_REQUEST = 'GIFT_LIST_DETAIL_REQUEST'
export const GIFT_LIST_DETAIL_SUCCESS = 'GIFT_LIST_DETAIL_SUCCESS'
export const GIFT_LIST_DETAIL_FAIL = 'GIFT_LIST_DETAIL_FAIL'

export const GIFT_CREATE_REQUEST = 'GIFT_CREATE_REQUEST'
export const GIFT_CREATE_SUCCESS = 'GIFT_CREATE_SUCCESS'
export const GIFT_CREATE_FAIL = 'GIFT_CREATE_FAIL'
export const GIFT_CREATE_RESET = 'GIFT_CREATE_RESET'

export const GIFT_UPDATE_REQUEST = 'GIFT_UPDATE_REQUEST'
export const GIFT_UPDATE_SUCCESS = 'GIFT_UPDATE_SUCCESS'
export const GIFT_UPDATE_FAIL = 'GIFT_UPDATE_FAIL'
export const GIFT_UPDATE_RESET = 'GIFT_UPDATE_RESET'