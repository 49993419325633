import {
  FUSION_CREATE_REQUEST,
  FUSION_CREATE_SUCCESS,
  FUSION_CREATE_FAIL,
  FUSION_CREATE_RESET,
} from '../constants/fusionConstants'

export const fusionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FUSION_CREATE_REQUEST:
      return {loading: true}
    case FUSION_CREATE_SUCCESS:
      return {loading: false, success: action.payload}
    case FUSION_CREATE_FAIL:
      return {loading: false, error: action.payload}
    case FUSION_CREATE_RESET:
      return {}
    default:
      return state
  }
}