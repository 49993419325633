export const logo = ['608 134', `
  <title>coreui react pro</title>
  <g transform="translate(0.000000,138.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M0 690 l0 -690 1830 0 1830 0 0 690 0 690 -1830 0 -1830 0 0 -690z
m1927 583 c60 -31 96 -91 100 -166 5 -76 -20 -126 -85 -172 -38 -26 -53 -30
-107 -30 -54 0 -69 4 -107 30 -58 42 -88 96 -88 163 0 151 152 244 287 175z
m-883 -53 c58 -22 90 -125 61 -198 -30 -73 -99 -104 -151 -67 -53 36 -70 144
-35 214 28 54 72 72 125 51z m483 -12 c42 -34 57 -152 28 -208 -30 -57 -95
-78 -143 -44 -74 51 -68 220 9 262 27 14 83 9 106 -10z m1206 -109 c21 -68 44
-127 50 -131 16 -10 8 -14 -34 -21 -41 -6 -73 7 -51 21 9 6 11 18 6 36 -6 24
-11 27 -43 24 -31 -3 -36 -7 -39 -30 -2 -20 1 -28 12 -28 25 0 19 -20 -6 -21
-110 -6 -200 4 -160 18 8 3 12 30 11 91 l-1 87 -25 -98 c-14 -55 -30 -96 -36
-94 -6 2 -22 46 -36 98 l-26 94 -3 -80 c-2 -66 0 -82 14 -92 10 -7 16 -15 13
-17 -5 -5 -46 -9 -74 -7 -19 2 -19 2 -2 15 14 11 17 30 17 125 0 67 -4 111
-10 111 -5 0 -10 5 -10 11 0 6 19 9 47 7 l47 -3 17 -68 c9 -38 19 -70 21 -72
2 -3 13 29 23 70 l18 75 49 0 c26 0 48 -4 48 -10 0 -5 -7 -10 -15 -10 -12 0
-15 -20 -15 -116 0 -114 0 -115 23 -112 19 3 28 20 58 113 21 61 39 118 42
129 12 39 32 5 70 -115z m178 120 c15 -7 24 -7 35 2 12 10 14 3 14 -46 0 -63
-3 -64 -28 -13 -13 25 -24 34 -48 36 -21 2 -33 -2 -37 -12 -11 -27 3 -43 49
-61 24 -9 51 -26 59 -38 23 -32 19 -96 -7 -124 -24 -25 -76 -31 -96 -11 -10
10 -15 10 -22 -2 -13 -21 -17 -12 -22 48 -6 60 11 71 30 20 20 -57 82 -75 97
-28 9 28 0 38 -59 64 -64 28 -84 65 -67 119 16 48 61 68 102 46z m-2031 -11
c1 -7 3 -30 6 -50 7 -50 -9 -48 -29 2 -15 38 -36 53 -38 28 -1 -7 -3 -56 -5
-108 -3 -79 -1 -97 13 -107 9 -7 15 -15 12 -17 -5 -5 -53 -9 -86 -7 -25 1 -32
21 -8 21 12 0 15 19 15 115 0 89 -3 115 -13 115 -14 0 -37 -40 -37 -65 0 -8
-4 -15 -10 -15 -5 0 -10 23 -10 50 l0 50 95 0 c69 0 95 -3 95 -12z m360 2 c0
-5 -7 -10 -15 -10 -12 0 -15 -13 -15 -57 l0 -58 19 24 c10 14 26 37 36 53 15
25 15 29 1 34 -32 12 -15 24 34 24 48 0 66 -12 36 -23 -8 -3 -26 -21 -40 -40
l-26 -34 35 -77 c19 -42 39 -76 46 -76 6 0 9 -6 6 -12 -2 -9 -20 -12 -52 -10
-54 3 -66 9 -46 22 11 6 11 16 -5 53 -14 35 -22 43 -31 35 -19 -15 -17 -88 2
-88 21 0 19 -20 -2 -21 -19 -2 -59 -2 -80 0 -18 1 -16 21 2 21 12 0 15 19 15
110 0 89 -3 112 -15 116 -32 13 -13 24 40 24 30 0 55 -4 55 -10z m1030 -40 c0
-27 -2 -50 -5 -50 -3 0 -11 16 -19 35 -10 23 -24 37 -41 41 -33 8 -45 -7 -45
-59 0 -31 3 -38 18 -35 9 2 18 13 20 26 4 24 17 29 25 10 4 -13 -2 -110 -8
-116 -2 -2 -9 9 -17 24 -20 41 -33 34 -36 -19 -3 -52 8 -64 52 -53 16 4 29 18
37 41 19 51 31 43 27 -17 l-3 -53 -80 2 c-44 1 -88 2 -97 2 -22 1 -24 21 -3
21 12 0 15 19 15 115 0 96 -3 115 -15 115 -8 0 -15 5 -15 10 0 6 38 10 95 10
l95 0 0 -50z m-1006 -342 c12 -20 -14 -102 -34 -106 -10 -2 -19 9 -25 30 -8
27 -15 34 -43 36 -38 4 -34 12 -88 -175 -36 -127 -37 -133 -20 -146 17 -13 17
-14 1 -30 -17 -17 -105 -24 -139 -11 -24 9 -19 33 8 44 21 7 30 26 55 107 16
54 36 124 44 156 l15 57 -30 0 c-22 0 -39 -10 -62 -35 -37 -40 -56 -45 -56
-15 0 30 26 87 43 96 9 5 85 8 169 8 121 -2 155 -5 162 -16z m515 16 c35 -7
41 -28 11 -44 -25 -13 -25 -24 1 -80 l20 -44 25 29 c55 66 58 74 40 94 -22 24
-20 30 11 42 40 15 125 2 121 -19 -2 -9 -12 -21 -24 -27 -25 -12 -35 -22 -106
-106 -51 -60 -57 -73 -68 -138 -11 -67 -11 -73 6 -87 18 -14 18 -14 0 -29 -28
-22 -134 -21 -143 1 -4 10 2 23 15 33 15 11 27 39 38 87 l15 71 -32 77 c-18
44 -43 84 -55 93 -54 35 29 66 125 47z m454 -8 c121 -52 131 -243 18 -356 -55
-55 -112 -71 -174 -51 -126 42 -144 252 -30 363 60 58 120 72 186 44z m283 4
l28 -11 -27 -25 c-15 -14 -27 -33 -27 -43 0 -10 -3 -32 -6 -48 -6 -29 -4 -30
35 -37 23 -3 58 -3 80 0 38 6 39 7 45 57 6 41 4 54 -8 63 -30 22 -18 41 29 49
54 8 105 -1 105 -19 0 -6 -8 -19 -19 -27 -19 -17 -36 -102 -48 -247 -5 -64 -4
-74 13 -87 19 -15 19 -15 0 -30 -25 -19 -106 -19 -132 0 -19 14 -19 15 5 34
18 14 26 33 32 77 4 33 4 63 0 67 -4 4 -39 7 -77 5 l-69 -3 -8 -63 c-8 -54 -6
-66 8 -82 15 -16 15 -22 5 -35 -16 -19 -109 -20 -134 -2 -18 13 -18 15 4 35
25 23 32 56 50 211 12 96 11 105 -5 117 -48 35 51 70 121 44z m-987 -22 c5
-13 18 -92 29 -177 15 -119 24 -158 37 -167 23 -17 12 -44 -17 -45 -13 0 -39
-2 -59 -5 -40 -5 -89 9 -89 26 0 6 11 15 25 20 24 9 28 14 28 45 0 36 -4 40
-40 41 -21 0 -52 4 -70 8 -29 8 -33 5 -59 -34 -26 -39 -27 -43 -11 -55 27 -19
20 -42 -14 -49 -99 -20 -159 13 -90 49 27 13 64 55 86 95 6 11 3 10 -12 -2
-41 -37 -63 -9 -32 41 21 34 61 55 90 46 14 -5 28 9 63 62 42 62 44 68 30 84
-24 26 -8 39 47 39 42 0 51 -3 58 -22z m-980 -559 c-7 -4 -19 -4 -28 -1 -13 5
-11 8 7 10 17 3 22 10 24 35 l1 32 4 -34 c3 -20 0 -37 -8 -42z m165 32 c15
-28 15 -28 -14 -27 -17 1 -30 2 -30 3 0 5 24 53 26 53 2 0 10 -13 18 -29z m67
-21 c10 0 19 -4 19 -10 0 -5 -10 -8 -22 -6 -18 2 -23 10 -24 37 -2 26 -1 28 3
7 3 -19 10 -28 24 -28z m123 44 c24 -9 20 -51 -6 -57 -28 -8 -38 -1 -38 27 0
34 12 42 44 30z m86 -4 c0 -5 -8 -10 -17 -11 -13 0 -14 -2 -4 -6 11 -5 11 -7
0 -14 -11 -7 -9 -9 5 -9 14 0 17 -3 10 -10 -16 -16 -44 0 -44 24 0 13 3 26 7
29 10 11 43 8 43 -3z m61 -40 c10 0 19 -4 19 -8 0 -5 -10 -8 -22 -7 -19 1 -23
8 -24 36 -1 26 -1 28 3 7 3 -19 10 -28 24 -28z m164 33 c11 28 25 19 24 -15 0
-26 -2 -29 -8 -13 -8 19 -8 19 -19 0 -10 -20 -11 -20 -18 -1 -7 19 -8 19 -15
0 -7 -16 -8 -15 -8 7 -1 34 9 43 25 21 12 -16 13 -16 19 1z m175 7 c12 -8 9
-10 -12 -10 -18 0 -28 -5 -28 -15 0 -10 10 -15 28 -15 22 -1 24 -2 10 -10 -9
-6 -23 -7 -32 -4 -19 7 -21 50 -3 57 18 8 21 8 37 -3z m151 -4 c18 17 19 16
18 -17 0 -26 -2 -29 -8 -15 -7 19 -8 19 -15 1 -6 -16 -9 -17 -16 -5 -7 10 -10
11 -10 3 0 -7 -4 -13 -10 -13 -11 0 -14 43 -3 54 4 3 11 -1 16 -9 8 -15 11
-15 28 1z m140 0 c18 17 19 16 18 -17 -1 -19 -3 -26 -6 -16 -5 18 -5 18 -19 0
-8 -11 -13 -13 -14 -5 0 6 -5 12 -11 12 -5 0 -7 -4 -4 -10 3 -5 1 -10 -4 -10
-12 0 -15 43 -4 54 4 3 11 -1 16 -9 8 -15 11 -15 28 1z m99 -36 c0 -3 -13 -5
-30 -6 l-29 -1 16 30 15 31 14 -25 c7 -13 13 -26 14 -29z m70 40 c12 -8 9 -10
-12 -10 -18 0 -28 -5 -28 -15 0 -10 10 -15 28 -15 22 -1 24 -2 10 -10 -9 -6
-23 -7 -32 -4 -19 7 -21 50 -3 57 18 8 21 8 37 -3z m80 -40 c0 -3 -13 -5 -30
-6 l-29 -1 16 30 15 31 14 -25 c7 -13 13 -26 14 -29z m64 -1 c-4 -8 -9 -7 -15
2 -6 10 -9 11 -9 2 0 -7 -4 -13 -10 -13 -11 0 -14 43 -3 53 10 10 45 -32 37
-44z m146 35 c11 -13 10 -16 -8 -21 -12 -3 -22 -9 -22 -14 0 -5 -4 -9 -10 -9
-11 0 -14 43 -3 53 11 12 30 8 43 -9z m70 6 c0 -5 -8 -10 -17 -10 -14 0 -15
-2 -4 -9 11 -7 11 -9 0 -14 -8 -3 -3 -6 11 -6 19 -1 21 -4 12 -13 -23 -23 -64
25 -46 54 7 12 44 10 44 -2z m77 -1 c6 -6 8 -19 6 -30 -4 -16 -8 -17 -20 -7
-11 9 -16 9 -24 -3 -6 -10 -9 -8 -9 12 0 38 23 52 47 28z m197 -18 c15 -28 15
-28 -14 -27 -17 1 -30 2 -30 3 0 5 24 53 26 53 2 0 10 -13 18 -29z m81 19 c3
-5 -2 -11 -12 -11 -17 -1 -17 -2 0 -6 19 -5 23 -28 7 -38 -6 -4 -19 -4 -28 0
-14 7 -13 9 8 15 24 8 24 8 3 9 -35 2 -27 36 9 40 4 1 10 -3 13 -9z m85 -40
c0 -3 -13 -5 -29 -6 -30 -1 -32 12 -8 44 8 11 13 9 23 -10 8 -13 13 -25 14
-28z m73 26 c3 14 5 12 6 -8 1 -30 -13 -38 -23 -13 -7 20 -24 19 -29 -2 -3
-12 -5 -9 -6 8 -1 36 10 44 30 18 16 -22 17 -22 22 -3z m127 14 c0 -5 -8 -10
-17 -10 -15 0 -15 -2 -4 -9 11 -7 10 -10 -5 -14 -16 -4 -15 -5 4 -6 13 0 21
-5 18 -10 -3 -5 -15 -7 -26 -4 -14 4 -20 14 -20 34 0 24 4 29 25 29 14 0 25
-4 25 -10z m64 -41 c-4 -8 -9 -7 -15 2 -6 10 -9 11 -9 2 0 -7 -4 -13 -10 -13
-11 0 -14 43 -3 53 10 10 45 -32 37 -44z m25 19 c0 -31 -1 -31 -10 -10 -5 13
-7 28 -3 33 10 16 14 9 13 -23z m75 -3 c5 -17 1 -20 -24 -21 l-29 -1 15 30
c16 30 27 28 38 -8z m73 28 c16 -6 15 -8 -7 -14 -24 -7 -24 -7 -2 -8 25 -1 30
-26 6 -35 -9 -3 -23 -2 -32 4 -14 8 -11 10 13 10 28 1 26 3 -14 16 -11 3 -11
7 -1 19 14 17 13 17 37 8z m-2465 -40 c7 2 12 12 12 21 -1 9 2 16 7 16 15 0
10 -48 -6 -54 -25 -9 -45 5 -44 33 l0 26 10 -23 c5 -13 15 -22 21 -19z m475 0
c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m213 18 c14 -27 14 -28
-19 -28 -27 0 -28 2 -18 24 13 28 23 29 37 4z m150 0 c14 -26 14 -26 -19 -27
-27 -1 -28 1 -19 22 14 29 24 30 38 5z m559 -8 c0 -25 -1 -26 -9 -8 -12 27
-12 35 0 35 6 0 10 -12 9 -27z m327 7 c8 0 14 5 14 10 0 6 5 10 10 10 6 0 10
-11 10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 5 -10 10 0 17 -28 11 -33 -7 -3 -10
-5 -2 -5 17 0 19 2 29 5 23 2 -7 11 -13 19 -13z m74 -5 c0 -14 -4 -25 -10 -25
-5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m-810 11 c0 -11 -19
-15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-710 -116 c11 -7 7 -10 -17 -10
-18 0 -33 -4 -33 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -5 -9 -10 -20
-10 -11 0 -20 -4 -20 -10 0 -5 14 -10 30 -10 17 0 30 -5 30 -11 0 -6 -14 -9
-32 -7 -31 3 -33 6 -36 45 -3 41 -1 44 38 52 3 0 12 -4 20 -9z m487 -14 c-16
-13 -16 -15 4 -40 24 -30 11 -36 -16 -6 -20 22 -35 18 -35 -11 0 -11 -4 -17
-10 -14 -13 8 -13 85 -1 85 5 0 11 -7 13 -16 3 -14 6 -14 28 4 14 11 27 18 30
15 3 -2 -3 -10 -13 -17z m1194 14 c5 0 9 -4 9 -9 0 -5 -15 -8 -32 -7 -29 1
-33 -2 -33 -24 0 -22 4 -25 34 -23 31 1 32 0 17 -15 -15 -15 -19 -15 -44 -2
-35 20 -40 53 -10 74 13 9 29 14 36 11 8 -3 18 -5 23 -5z m-1783 -7 c-13 -3
-18 -15 -18 -39 0 -19 -4 -34 -10 -34 -5 0 -10 15 -10 34 0 24 -5 36 -17 39
-10 3 2 5 27 5 25 0 37 -2 28 -5z m217 -35 c0 -39 -11 -50 -21 -23 -9 22 -34
18 -34 -6 0 -11 -4 -17 -10 -14 -5 3 -10 24 -10 46 l0 40 38 -3 c36 -3 37 -4
37 -40z m118 10 c6 -39 -14 -60 -53 -55 -22 3 -25 9 -28 45 l-3 42 39 0 c37 0
40 -2 45 -32z m93 -3 c18 -44 18 -66 0 -42 -15 19 -41 23 -51 7 -3 -5 -11 -10
-16 -10 -5 0 0 18 11 40 11 22 25 40 31 40 5 0 17 -16 25 -35z m64 -5 c0 -22
-4 -40 -10 -40 -5 0 -10 18 -10 40 0 22 5 40 10 40 6 0 10 -18 10 -40z m281
12 c12 -31 -22 -64 -59 -60 -24 3 -27 8 -30 46 l-3 43 42 -3 c33 -2 44 -8 50
-26z m92 -14 c9 -23 15 -44 12 -46 -2 -3 -7 3 -11 12 -8 21 -50 21 -58 0 -4
-9 -9 -15 -12 -12 -9 9 28 88 41 88 6 0 19 -19 28 -42z m89 15 l28 -27 1 30
c1 28 1 28 8 4 7 -25 3 -60 -7 -60 -3 0 -18 15 -33 33 l-28 32 -1 -32 c0 -18
-4 -33 -8 -33 -7 0 -9 67 -3 78 4 7 18 -1 43 -25z m186 20 c-13 -3 -18 -15
-18 -39 0 -19 -4 -34 -10 -34 -5 0 -10 15 -10 34 0 24 -5 36 -17 39 -10 3 2 5
27 5 25 0 37 -2 28 -5z m85 0 c-36 -5 -46 -23 -13 -23 11 0 20 -4 20 -10 0 -5
-9 -10 -20 -10 -33 0 -23 -18 13 -22 25 -3 28 -6 14 -12 -9 -5 -28 -5 -42 -2
-21 5 -25 12 -25 46 l0 40 43 -2 c36 -1 38 -2 10 -5z m133 -42 c0 -43 0 -43
-17 -22 -20 24 -39 28 -39 7 0 -8 -4 -18 -10 -21 -6 -4 -10 12 -10 40 l0 46
38 -3 c37 -3 37 -4 38 -47z m112 26 c3 -12 -5 -19 -27 -23 -17 -4 -31 -13 -31
-20 0 -8 -4 -14 -10 -14 -5 0 -10 18 -10 41 l0 40 37 -3 c27 -2 39 -8 41 -21z
m75 16 c-36 -5 -46 -23 -13 -23 11 0 20 -4 20 -10 0 -5 -9 -10 -20 -10 -33 0
-23 -18 13 -22 25 -3 28 -6 14 -12 -9 -5 -28 -5 -42 -2 -21 5 -25 12 -25 46
l0 40 43 -2 c36 -1 38 -2 10 -5z m132 -35 c0 -39 -11 -50 -21 -23 -4 8 -12 15
-20 15 -8 0 -14 -7 -14 -15 0 -8 -4 -15 -10 -15 -5 0 -10 18 -10 41 l0 40 38
-3 c36 -3 37 -4 37 -40z m210 2 c10 -22 15 -43 12 -47 -4 -3 -9 2 -13 11 -7
18 -39 22 -49 6 -3 -5 -10 -10 -15 -10 -5 0 -1 18 8 40 9 22 22 40 28 40 6 0
19 -18 29 -40z m55 23 c10 -17 10 -17 20 0 6 9 16 17 22 17 6 0 3 -11 -6 -25
-9 -13 -16 -31 -16 -40 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 9 -7 27 -16
40 -9 14 -12 25 -6 25 6 0 16 -8 22 -17z m132 -23 c9 -22 13 -42 8 -45 -4 -3
-10 2 -14 10 -7 20 -42 19 -50 -1 -4 -9 -9 -14 -13 -11 -9 9 28 87 41 87 6 0
19 -18 28 -40z"/>
<path d="M1752 1229 c-127 -82 -70 -279 81 -279 57 0 98 19 125 58 49 69 32
171 -36 217 -48 33 -122 34 -170 4z m127 0 c33 -12 36 -16 22 -38 -5 -8 -16
-11 -30 -6 -19 6 -21 2 -21 -29 0 -35 1 -36 38 -36 34 0 38 2 37 27 0 14 6 28
12 30 27 10 44 -97 23 -137 -15 -27 -43 -14 -37 16 4 22 1 24 -34 24 -38 0
-39 -1 -39 -36 0 -29 3 -34 18 -30 10 4 27 0 38 -8 18 -14 17 -15 -18 -30 -47
-20 -71 -20 -109 0 -25 13 -28 18 -18 30 8 10 21 13 36 9 21 -5 23 -2 23 30 0
34 -1 35 -40 35 -31 0 -38 -3 -34 -15 4 -8 0 -22 -7 -31 -12 -14 -14 -14 -26
2 -7 10 -13 39 -13 64 0 25 6 54 13 64 12 16 14 16 26 2 7 -9 11 -23 7 -31 -4
-12 3 -15 34 -15 39 0 40 1 40 34 0 32 -3 35 -27 33 -39 -3 -43 28 -6 41 37
14 54 14 92 1z"/>
<path d="M980 1185 c-17 -20 -24 -101 -16 -159 9 -59 40 -80 68 -46 14 17 18
40 18 102 0 44 -5 88 -10 99 -13 23 -43 25 -60 4z"/>
<path d="M1430 1174 c-11 -28 -14 -153 -4 -178 12 -31 42 -38 64 -16 16 16 20
33 20 94 0 95 -11 126 -45 126 -18 0 -28 -7 -35 -26z"/>
<path d="M2652 1101 c-14 -39 -12 -44 16 -39 17 3 18 6 7 38 l-12 34 -11 -33z"/>
<path d="M2121 768 c-56 -51 -87 -188 -57 -246 20 -38 59 -51 95 -31 15 8 39
40 56 74 37 74 42 169 11 204 -25 28 -75 28 -105 -1z"/>
<path d="M1421 680 l-32 -45 21 -17 c12 -10 31 -18 41 -18 16 0 19 7 18 43 0
23 -4 51 -8 62 -7 17 -11 14 -40 -25z"/>
<path d="M888 273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2258 293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M960 160 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10z"/>
<path d="M1070 160 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M1070 120 c0 -5 9 -10 19 -10 11 0 23 5 26 10 4 6 -5 10 -19 10 -14
0 -26 -4 -26 -10z"/>
<path d="M1190 146 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M1500 140 c0 -25 4 -30 24 -30 26 0 39 20 30 45 -4 8 -17 15 -30 15
-20 0 -24 -5 -24 -30z"/>
<path d="M1626 145 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M2100 160 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M2210 160 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M2420 160 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10 -8
0 -14 -4 -14 -10z"/>
<path d="M2650 146 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M2836 151 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
</g>
`]
