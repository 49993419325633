import {
  GIFT_LIST_REQUEST, 
  GIFT_LIST_SUCCESS, 
  GIFT_LIST_FAIL, 
  GIFT_CREATE_REQUEST, 
  GIFT_CREATE_SUCCESS, 
  GIFT_CREATE_FAIL, 
  GIFT_CREATE_RESET, 
  GIFT_LIST_DETAIL_REQUEST, 
  GIFT_LIST_DETAIL_SUCCESS, 
  GIFT_LIST_DETAIL_FAIL, 
  GIFT_UPDATE_REQUEST, 
  GIFT_UPDATE_SUCCESS, 
  GIFT_UPDATE_FAIL, 
  GIFT_UPDATE_RESET
} from '../constants/giftConstants'

export const giftListReducer = (state = {gifts: []}, action) => {
  switch (action.type) {
      case GIFT_LIST_REQUEST:
          return {loading: true, gifts: []}
      case GIFT_LIST_SUCCESS:
          return {loading: false, gifts: action.payload}
      case GIFT_LIST_FAIL:
          return {loading: false, error: action.payload}
      default:
          return state
  }
}

export const giftDetailsReducer = (state = { gift : []}, action) => {
  switch (action.type) {
      case GIFT_LIST_DETAIL_REQUEST:
          return {loading: true, gift: []}
      case GIFT_LIST_DETAIL_SUCCESS:
          return {loading: false, gift: action.payload}
      case GIFT_LIST_DETAIL_FAIL:
          return {loading: false, error: action.payload}
      default:
          return state
  }
}

export const giftCreateReducer = (state = {}, action) => {
  switch (action.type) {
      case GIFT_CREATE_REQUEST:
          return {loading: true}
      case GIFT_CREATE_SUCCESS:
          return {loading: false, success: action.payload}
      case GIFT_CREATE_FAIL:
          return {loading: false, error: action.payload}
      case GIFT_CREATE_RESET:
          return {}
      default:
          return state
  }
}

export const giftUpdateReducer = (state = {}, action) => {
  switch (action.type) {
      case GIFT_UPDATE_REQUEST:
          return {loading: true}
      case GIFT_UPDATE_SUCCESS:
          return {loading: false, success: action.payload}
      case GIFT_UPDATE_FAIL:
          return {loading: false, error: action.payload}
      case GIFT_UPDATE_RESET:
          return {}
      default:
          return state
  }
}