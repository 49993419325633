export const LOAN_LIST_REQUEST = 'LOAN_LIST_REQUEST'
export const LOAN_LIST_SUCCESS = 'LOAN_LIST_SUCCESS'
export const LOAN_LIST_FAIL = 'LOAN_LIST_FAIL'

export const LOAN_LIST_DETAIL_REQUEST = 'LOAN_LIST_DETAIL_REQUEST'
export const LOAN_LIST_DETAIL_SUCCESS = 'LOAN_LIST_DETAIL_SUCCESS'
export const LOAN_LIST_DETAIL_FAIL = 'LOAN_LIST_DETAIL_FAIL'

export const LOAN_CREATE_REQUEST = 'LOAN_CREATE_REQUEST'
export const LOAN_CREATE_SUCCESS = 'LOAN_CREATE_SUCCESS'
export const LOAN_CREATE_FAIL = 'LOAN_CREATE_FAIL'
export const LOAN_CREATE_RESET = 'LOAN_CREATE_RESET'

export const LOAN_PAY_REQUEST = 'LOAN_PAY_REQUEST'
export const LOAN_PAY_SUCCESS = 'LOAN_PAY_SUCCESS'
export const LOAN_PAY_FAIL = 'LOAN_PAY_FAIL'
export const LOAN_PAY_RESET = 'LOAN_PAY_RESET'

export const LOAN_SETTLE_REQUEST = 'LOAN_SETTLE_REQUEST'
export const LOAN_SETTLE_SUCCESS = 'LOAN_SETTLE_SUCCESS'
export const LOAN_SETTLE_FAIL = 'LOAN_SETTLE_FAIL'
export const LOAN_SETTLE_RESET = 'LOAN_SETTLE_RESET'

export const LOAN_ADD_REQUEST = 'LOAN_ADD_REQUEST'
export const LOAN_ADD_SUCCESS = 'LOAN_ADD_SUCCESS'
export const LOAN_ADD_FAIL = 'LOAN_ADD_FAIL'
export const LOAN_ADD_RESET = 'LOAN_ADD_RESET'

export const LOAN_EDIT_INTEREST_REQUEST = 'LOAN_EDIT_INTEREST_REQUEST'
export const LOAN_EDIT_INTEREST_SUCCESS = 'LOAN_EDIT_INTEREST_SUCCESS'
export const LOAN_EDIT_INTEREST_FAIL = 'LOAN_EDIT_INTEREST_FAIL'
export const LOAN_EDIT_INTEREST_RESET = 'LOAN_EDIT_INTEREST_RESET'