import {
  LOAN_LIST_REQUEST, 
  LOAN_LIST_SUCCESS, 
  LOAN_LIST_FAIL,
  LOAN_LIST_DETAIL_REQUEST, 
  LOAN_LIST_DETAIL_SUCCESS, 
  LOAN_LIST_DETAIL_FAIL,
  LOAN_CREATE_REQUEST,
  LOAN_CREATE_SUCCESS,
  LOAN_CREATE_FAIL,
  LOAN_CREATE_RESET,
  LOAN_PAY_REQUEST,
  LOAN_PAY_SUCCESS,
  LOAN_PAY_FAIL,
  LOAN_PAY_RESET,
  LOAN_SETTLE_REQUEST,
  LOAN_SETTLE_SUCCESS,
  LOAN_SETTLE_FAIL,
  LOAN_SETTLE_RESET,
  LOAN_ADD_REQUEST,
  LOAN_ADD_SUCCESS,
  LOAN_ADD_FAIL,
  LOAN_ADD_RESET,
  LOAN_EDIT_INTEREST_REQUEST,
  LOAN_EDIT_INTEREST_SUCCESS,
  LOAN_EDIT_INTEREST_FAIL,
  LOAN_EDIT_INTEREST_RESET
} from '../constants/loanConstants'

export const loanListReducer = (state = {loans: []}, action) => {
  switch (action.type) {
    case LOAN_LIST_REQUEST:
      return {loading: true, loans: []}
    case LOAN_LIST_SUCCESS:
      return {loading: false, loans: action.payload}
    case LOAN_LIST_FAIL:
      return {loading: false, error: action.payload}
    default:
      return state
  }
}

export const loanDetailsReducer = (state = { loans : []}, action) => {
  switch (action.type) {
    case LOAN_LIST_DETAIL_REQUEST:
      return {loading: true, loans: []}
    case LOAN_LIST_DETAIL_SUCCESS:
      return {loading: false, loans: action.payload}
    case LOAN_LIST_DETAIL_FAIL:
      return {loading: false, error: action.payload}
    default:
      return state
  }
}

export const loanCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_CREATE_REQUEST:
      return {loading: true}
    case LOAN_CREATE_SUCCESS:
      return {loading: false, success: action.payload}
    case LOAN_CREATE_FAIL:
      return {loading: false, error: action.payload}
    case LOAN_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const loanPayReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_PAY_REQUEST:
      return {loading: true}
    case LOAN_PAY_SUCCESS:
      return {loading: false, success: action.payload}
    case LOAN_PAY_FAIL:
      return {loading: false, error: action.payload}
    case LOAN_PAY_RESET:
      return {}
    default:
      return state
  }
}

export const loanSettleReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_SETTLE_REQUEST:
      return {loading: true}
    case LOAN_SETTLE_SUCCESS:
      return {loading: false, success: action.payload}
    case LOAN_SETTLE_FAIL:
      return {loading: false, error: action.payload}
    case LOAN_SETTLE_RESET:
      return {}
    default:
      return state
  }
}

export const loanAddReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_ADD_REQUEST:
      return {loading: true}
    case LOAN_ADD_SUCCESS:
      return {loading: false, success: action.payload}
    case LOAN_ADD_FAIL:
      return {loading: false, error: action.payload}
    case LOAN_ADD_RESET:
      return {}
    default:
      return state
  }
}

export const loanEditInterestReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAN_EDIT_INTEREST_REQUEST:
      return {loading: true}
    case LOAN_EDIT_INTEREST_SUCCESS:
      return {loading: false, success: action.payload}
    case LOAN_EDIT_INTEREST_FAIL:
      return {loading: false, error: action.payload}
    case LOAN_EDIT_INTEREST_RESET:
      return {}
    default:
      return state
  }
}