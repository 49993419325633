import {
  PURCHASE_CREATE_REQUEST,
  PURCHASE_CREATE_SUCCESS,
  PURCHASE_CREATE_FAIL,
  PURCHASE_CREATE_RESET,
} from '../constants/purchaseConstants'

export const purchaseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_CREATE_REQUEST:
      return {loading: true}
    case PURCHASE_CREATE_SUCCESS:
      return {loading: false, success: action.payload}
    case PURCHASE_CREATE_FAIL:
      return {loading: false, error: action.payload}
    case PURCHASE_CREATE_RESET:
      return {}
    default:
      return state
  }
}