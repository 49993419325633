import {
    TRANSACTION_DETAILS_BY_TRANSACTION_REQUEST,
    TRANSACTION_DETAILS_BY_TRANSACTION_SUCCESS,
    TRANSACTION_DETAILS_BY_TRANSACTION_FAIL,
    TRANSACTION_DETAILS_BY_TRANSACTION_RESET
} from '../constants/transactionDetailsConstants'

export const transactionDetailsByTransactionReducers = (state = { transactionDetails: [] }, action) => {
    switch (action.type) {
      case TRANSACTION_DETAILS_BY_TRANSACTION_REQUEST:
        return {
          loading: true,
        }
      case TRANSACTION_DETAILS_BY_TRANSACTION_SUCCESS:
        return {
          loading: false,
          transactionDetails: action.payload,
        }
      case TRANSACTION_DETAILS_BY_TRANSACTION_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case TRANSACTION_DETAILS_BY_TRANSACTION_RESET:
        return {
          transactionDetails: [],
        }
      default:
        return state
    }
  }